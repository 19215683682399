import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'DE',
    resources: {},
    lng: 'DE',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })

export default () =>
  new Promise(res => {
    const namespaces = [
      'landing',
      'form',
      'app',
      'validation',
      'result',
      'licenses',
    ]
    const countries = [
      'de',
      'dk',
      'es',
      'fr',
      'it',
      'nl',
      'no',
      'pl',
      'pt',
      'uk',
    ]
    Promise.all(
      countries.map(country =>
        namespaces.map(item =>
          import(`../../resources/base/content/${country}/${item}.json`).then(
            module => {
              i18n.addResourceBundle(
                country.toUpperCase(),
                item,
                module.default,
                true,
                true
              )
            }
          )
        )
      )
    ).then(res)
  })
