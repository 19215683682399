export const SET_FORM_DATA = 'form//SET_FORM_DATA'
export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'
export const SET_MARKET = 'form//SET_MARKET'

export function setFormData(data) {
  return {
    type: SET_FORM_DATA,
    payload: data,
  }
}
export function setMarket(data) {
  return {
    type: SET_MARKET,
    payload: data,
  }
}

export function resetFormData() {
  return {
    type: RESET_FORM_DATA,
  }
}

export function getFormData(store) {
  return store.form.data
}

const initialState = {
  data: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        data: {},
      }

    case SET_FORM_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case SET_MARKET:
      return {
        ...state,
        market: action.payload,
      }
    default:
      return state
  }
}
