import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, withRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import initI18n from './services/i18n'
import { persistor, store } from './services/redux'
import { history } from './services/routing'

import { getBrandName } from './config'

const ConnectedApp = React.lazy(() => import('./App'))

const chooseVwBrand = brand => {
  switch (brand) {
    case 'vwfs':
      return 'bluelabel'
    case 'vw6':
    case 'vw':
      return 'vw6'
    case 'vwcv':
      return 'vw'
    case 'vwn':
      return 'vw'
    case 'cariad':
    case 'vwgroup':
      return 'generic'
    default:
      return brand
  }
}

const getStyles = brand =>
  import(
    `@vwfs-bronson/bronson-${chooseVwBrand(brand)}/dist/css/style.min.css`
  ).then(() => import('./styles.scss'))

const PositionRestore = withRouter(props => {
  const [location, setLocation] = React.useState(props.location.pathname)
  if (location !== props.location.pathname) {
    setLocation(props.location.pathname)
    if (props.location.hash && props.location.hash !== '') {
      setTimeout(() => {
        const id = props.location.hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) element.scrollIntoView({ behavior: 'smooth' })
      }, 0)
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return null
})

function Init() {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    Promise.all([getStyles(getBrandName()), initI18n(getBrandName())]).then(
      () => {
        setLoaded(true)
      }
    )
  }, [])
  return loaded ? (
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <PositionRestore />
            <React.Fragment>
              <ConnectedApp />
            </React.Fragment>
          </Router>
        </PersistGate>
      </Provider>
    </React.Suspense>
  ) : null
}

if (module.hot) {
  module.hot.accept(render)
}

function render() {
  const rootElement = document.getElementById('root')
  ReactDOM.render(<Init />, rootElement)
}

render()
