export const REHYDRATE = 'persist//REHYDRATE'

const initialState = {
  data: {
    market: null,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        data: action.payload.form.market,
      }

    default:
      return state
  }
}
