import {
  availableCountriesList,
  availableCountriesListForAudi,
  availableCountriesListForCariad,
  availableCountriesListForVw,
  availableCountriesListForVwGroup,
} from './resources'

export const getBrandName = () => {
  if (
    typeof window.reactConfig === 'object' &&
    window.reactConfig.brand !== '%brand%'
  ) {
    return window.reactConfig.brand
  }
  return process.env.REACT_APP_WEBSITE_BRAND === 'vw5' ||
    process.env.REACT_APP_WEBSITE_BRAND === 'vw6'
    ? 'vw'
    : process.env.REACT_APP_WEBSITE_BRAND
}

export const availableCountriesByBrand = () => {
  switch (getBrandName()) {
    case 'audi':
      return availableCountriesListForAudi
    case 'vw':
      return availableCountriesListForVw
    case 'cariad':
      return availableCountriesListForCariad
    case 'vwgroup':
      return availableCountriesListForVwGroup
    default:
      return availableCountriesList
  }
}
