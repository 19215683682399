import { createBrowserHistory } from 'history'

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
})

function getCardUrlParameter(queryString) {
  const parameter = 'cardUrl='
  return queryString
    ? queryString.substring(queryString.indexOf(parameter) + parameter.length)
    : ''
}

export { history, getCardUrlParameter }
