import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import formReducer from './features/form.redux'
import dataModelsReducer from './features/data-models.redux'
import persistDataReducer from './features/persist.redux'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
}

const rootReducer = combineReducers({
  form: formReducer,
  dataModels: dataModelsReducer,
  persist: persistDataReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

/* eslint-disable no-underscore-dangle */
const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */

const persistor = persistStore(store)

export { store, persistor }
